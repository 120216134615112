import client from "./client";

const getMention = (id) => {
  return client
    .get(`/mention/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getMentions = (filter) => {
  return client
    .get(`/mentions`, filter)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const createMention = (data) => {
  return client
    .post("/mention", data)
    .then((response) => {
      return response;
    })
    .catch((error) => Promise.reject(error.response.data));
};

const updateMention = (data) => {
  return client
    .put(`/mention/${data.id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => Promise.reject(error.response.data));
};

const deleteMentions = (data) => {
  return client
    .put(`/mentions`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const mentionAPI = {
  getMention,
  createMention,
  updateMention,
  deleteMentions,
  getMentions,
};

export default mentionAPI;
